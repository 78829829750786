import React from 'react';
import {createRequestHelper} from './helpers/requestHelper';
import consumeToContext from './helpers/consumeToContext';
import {AuthContext} from './AuthContext';
import makeHttpRequest from 'sources/api/http/makeHttpRequest';

const deleteTrackHelper = createRequestHelper('deleteTrack');

export const TracksContext = React.createContext();

const tracksHelper = createRequestHelper('tracks', {list: [], count: 0});
const genresHelper = createRequestHelper('genres', {genres: [], categories: [], versions: [], VersionsData: []});

const TRACK_UPLOAD_STATUSES = {
  upload: { text: 'Uploading...', color: 'blue'},
  inQueue: { text: 'Waiting to upload', color: 'yellow'},
  requiredInfo: { text: 'Requires updated info to upload', color: 'orange'},
  error: { text: 'Failed to upload', color: 'red'}
};

class TracksContextContainer extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            ...genresHelper.initialState,
            ...tracksHelper.initialState,
            ...deleteTrackHelper.initialStat,
            artistResult: null,
            tracksToUpload: [],
            allPresets: [],
            bannerTracks: [],
            banners: false,
            isBannerAdding: false,
            isBannerAdded: false,
            isBannerFetching: false,
            trackUploading: false,
            trackFetching: false,
            isBannerUpdating: false,
            isBannerUpdatingRes: false,
            isBannerReAdded: false,
            isBannerReAdding: false,
            bannersCount: false,
            trackUploadingRes: {text: 'Uploading...', color: '#525252'}
            
        };

        this.funcs = {
            getTracks: this.getTracks,
            setTracksToUpload: this.setTracksToUpload,
            toggleHidden: this.toggleHidden,
            deleteTrackFromQueue: this.deleteTrackFromQueue,
            updateTrackToUpload: this.updateTrackToUpload,
            getTracksWithoutEmotions: this.getTracksWithoutEmotions,
            updateTracksEmotions: this.updateTracksEmotions,
            getTracksCountWithOrWithoutEmotions: this.getTracksCountWithOrWithoutEmotions,
            fetchPresets: this.fetchPresets,
            updateTracksNameGenre: this.updateTracksNameGenre,
            getGenresAndCategories: this.getGenresAndCategories,
            updateVersion : this.updateVersion,
            updateSongName: this.updateSongName,
            removeFromTrackUpload: this.removeFromTrackUpload,
            updateArtistName: this.updateArtistName,
            updateArtisteName: this.updateArtisteName,
            updateAdditionalVersion: this.updateAdditionalVersion,
            updateSubversion: this.updateSubversion,
            uploadAllTrack : this.uploadAllTrack,
            cancelAllUploads  : this.cancelAllUploads,
            addBannerTrack  : this.addBannerTrack,
            removeBannerTrack  : this.removeBannerTrack,
            createBanner  : this.createBanner,            
            setState: this.setState,
            fetchBanners: this.fetchBanners,
            setBannerStatus: this.setBannerStatus,
            setBannerOrder: this.setBannerOrder,
            reUploadlBanner: this.reUploadlBanner
        };

        this.getGenresAndCategories();
        this.fetchPresets()
    }

    setTracksToUpload = (files) => {
        const existingFiles = this.state.tracksToUpload.map(file => file.file.path);
        const newFiles = files.filter(file => !existingFiles.includes(file.file.path));
        const newTracksToUpload = [...this.state.tracksToUpload, ...newFiles];
        this.setNewTracksToUpload(newTracksToUpload);
    }

    setNewTracksToUpload(tracksToUpload) {
        tracksToUpload.forEach(track => {
            if (!track.metadata || track.status === TRACK_UPLOAD_STATUSES.error.text) {
                track.status = TRACK_UPLOAD_STATUSES.error.text;
                track.metadata = {};
                return;
            }
            if (track.metadata.status === TRACK_UPLOAD_STATUSES.upload.text) return;
            //const {metadata} = track;
            //if (metadata.version && metadata.genre && metadata.songName && metadata.key && metadata.artist && metadata.bpm) {
                
            if(!track.metadata.status || track.metadata.status !== TRACK_UPLOAD_STATUSES.upload.text) {
              track.metadata.status = TRACK_UPLOAD_STATUSES.inQueue.text
              track.metadata.color = TRACK_UPLOAD_STATUSES.inQueue.color
            };
            //} else {
                //track.metadata.status = TRACK_UPLOAD_STATUSES.requiredInfo.text;
            //}
        });
        this.setState({tracksToUpload});
    }

    async initializeUpload() {
        if (!this.state.currentUploadingTrack) {
            console.log(this.state.currentUploadingTrack, "initializeUpload------------1")
            const trackToUpload = this.state.tracksToUpload.find(track => {
                const {metadata} = track;
                return (!metadata.status || metadata.status !== TRACK_UPLOAD_STATUSES.upload.text) && (metadata.status === TRACK_UPLOAD_STATUSES.inQueue.text);
            });

            console.log(this.state.currentUploadingTrack, "initializeUpload---------------2")

            if (trackToUpload) {
                console.log(this.state.currentUploadingTrack, trackToUpload,"initializeUpload----------------3");
                await this.uploadTrack(trackToUpload);
            }
        }
    }

    getGenresAndCategories = async () => {
        try {
            const result = await makeHttpRequest({
                method: 'get',
                path: `/tracks/genresAndCategories`
            });
            this.setState(genresHelper.result(result))
        } catch (e) {
            console.log(e);
        }
    };

    cancelAllUploads = async () => {
        let tracksToUpload = [];
        this.setState({tracksToUpload});
    };

    updateVersion = async (title, version) => {
        this.setState(prevState => {
          const updatedTracks = prevState.tracksToUpload.map(elem => {
            if (elem.metadata.title === title) {
              return {
                ...elem,
                metadata: {
                  ...elem.metadata,
                  version: version,
                  trackVersionType: version,
                  trackVersionTypeUpdated: true
                }
              };
            }
            return elem;
          });
      
          return { tracksToUpload: updatedTracks };
        });
      };
    
      updateAdditionalVersion = async (title, additionalVersions) => {
          this.setState(prevState => {
            const updatedTracks = prevState.tracksToUpload.map(elem => {
              if (elem.metadata.title === title) {
                return {
                  ...elem,
                  metadata: {
                    ...elem.metadata,
                    additionalVersions: additionalVersions
                  }
                };
              }
              return elem;
            });
        
            return { tracksToUpload: updatedTracks };
          });
      };
    
      updateArtisteName = async (title, artisteName) => {
          this.setState(prevState => {
            const updatedTracks = prevState.tracksToUpload.map(elem => {
              if (elem.metadata.title === title) {
                return {
                  ...elem,
                  metadata: {
                    ...elem.metadata,
                    artisteName: artisteName
                  }
                };
              }
              return elem;
            });
        
            return { tracksToUpload: updatedTracks };
          });
      };
    
      updateSubversion = async (title, subversionType) => {
        this.setState(prevState => {
          const updatedTracks = prevState.tracksToUpload.map(elem => {
            if (elem.metadata.title === title) {
              const updatedMetadata = {
                ...elem.metadata,
                subversionType: subversionType
              };
              return {
                ...elem,
                metadata: updatedMetadata
              };
            }
            return elem;
          });
      
          return { tracksToUpload: updatedTracks };
        });
      };
      

    updateArtistName = async (title, artist) => {
        this.setState(prevState => {
          const updatedTracks = prevState.tracksToUpload.map(elem => {
            if (elem.metadata.title === title) {
              return {
                ...elem,
                metadata: {
                  ...elem.metadata,
                  artist: artist
                }
              };
            }
            return elem;
          });
      
          return { tracksToUpload: updatedTracks };
        });
    };

      updateSongName = async (title, songName) => {
          this.setState(prevState => {
            const updatedTracks = prevState.tracksToUpload.map(elem => {
              if (elem.metadata.title === title) {
                return {
                  ...elem,
                  metadata: {
                    ...elem.metadata,
                    songName: songName
                  }
                };
              }
              return elem;
            });
        
            return { tracksToUpload: updatedTracks };
          });
        };

        
    removeFromTrackUpload = async (path) => {
        const newTracksToUpload = this.state.tracksToUpload.filter(elem => elem.file.path !== path);
        this.setState({currentUploadingTrack: null, tracksToUpload: newTracksToUpload});
      };

    uploadTrack = async (track) => {
        const {file, metadata: {key, artist, genre, bpm, songName, version, subversion, title, preset}} = track;
        const {auth: {makeAuthenticateHttpRequest}} = this.props;
        track.metadata.status = TRACK_UPLOAD_STATUSES.upload.text;
        track.metadata.color = TRACK_UPLOAD_STATUSES.upload.color;
        this.setState({currentUploadingTrack: track});
        const encodedGenre = encodeURIComponent(genre); 
        try {
            await makeAuthenticateHttpRequest({
                method: 'post',
                path: `/tracks?key=${key.trim()}&artist=${artist.trim()}&bpm=${bpm}&songName=${songName.trim()}&genre=${encodedGenre}` +
                    `&version=${version}&subversion=${encodeURIComponent(subversion)}&title=${encodeURIComponent(title.trim())}&preset=${encodeURIComponent(preset)}`,
                files: {file}
            });
        } catch (e) {
            console.log(e);
        } finally {
            const newTracksToUpload = this.state.tracksToUpload.filter(elem => elem.id !== track.id);
            this.setState({currentUploadingTrack: null, tracksToUpload: newTracksToUpload});
        }
    };

    uploadAllTrack = async ({preset, genre, tracksToUpload, isAlbum, albumArtist, albumName}) => {
        try {
          this.setState({
            trackUploading: true,
            trackUploadingRes: { text: TRACK_UPLOAD_STATUSES.upload.text, color: TRACK_UPLOAD_STATUSES.upload.color }
          });
      
          const updatedTracks = this.state.tracksToUpload.map((elem) => {
            return {
                ...elem,
                metadata: {
                ...elem.metadata,
                status: TRACK_UPLOAD_STATUSES.upload.text,
                color: TRACK_UPLOAD_STATUSES.upload.color
                }
            };
          });

          // Update state with the modified tracks
          this.setState({ tracksToUpload: updatedTracks });

          const { auth: { makeAuthenticateHttpRequest } } = this.props;
        
          for (let index = 0; index < tracksToUpload.length; index++) {
            const track = tracksToUpload[index];
            let isUploaded = true;
            console.log({ track })
            const files = { files0: track.file };
            // const id = track.id;
            console.log(track, '----uploadAllTrack-------------track,----');
            console.log(updatedTracks,this.state.tracksToUpload, track, 'updatedTracks,this.state.tracksToUpload-------');
            try {
              await makeAuthenticateHttpRequest({
                method: 'post',
                path: `/tracks/adminBulkUploadTrack?isAlbum=${isAlbum}&albumArtist=${albumArtist}&albumName=${albumName}&preset=${encodeURIComponent(preset.trim())}&genre=${encodeURIComponent(genre.trim())}&tracks=[${encodeURIComponent(JSON.stringify(track))}]`,
                files: files
              });
            } catch (e) {
              console.error(e);
              isUploaded = false;
              this.setState({
                trackUploading: false,
                trackUploadingRes: { text: 'Upload failed: ' + e, color: 'red', status: 'processed' }
              });
            } finally {
              this.setState((prevState) => {
                const updatedTracksToUpload = (isUploaded === true)
                  ? prevState.tracksToUpload.filter((elem) => elem.id !== track.id)
                  : prevState.tracksToUpload.map((elem) => {
                      if (elem.id === track.id) {
                        // Update the status of the current track
                        return {
                          ...elem,
                          metadata: {
                          ...elem.metadata,
                          status: TRACK_UPLOAD_STATUSES.error.text,
                          color: TRACK_UPLOAD_STATUSES.error.color
                          }
                        }
                      }
                      return elem;
                    });
                return { tracksToUpload: updatedTracksToUpload };
              });
            }
          }
          this.setState({
            currentUploadingTrack: null,
            trackUploading: false,
            trackUploadingRes: { text: 'Uploaded process finished.', color: '#03c303', status: 'processed' }
          });
        } catch (error) {
          console.error('Error during bulk upload:', error);
          this.setState({
            trackUploading: false,
            trackUploadingRes: { text: 'Upload failed: ' + error.message, color: 'red', status: 'processed' }
          });
        }
      };

    deleteTrackFromQueue = async (track) => {
        const tracksToUpload = this.state.tracksToUpload.filter(elem => elem.id !== track.id);
        this.setState({tracksToUpload});
    };

    updateTrackToUpload = async (track, newFields) => {
        const tracksToUpload = this.state.tracksToUpload.map(elem => {
            if (elem.id === track.id) {
                elem.metadata = {...elem.metadata, ...newFields};
            }
            return elem;
        });
        this.setNewTracksToUpload(tracksToUpload);
    };

    toggleHidden = async (fields) => {
        const {auth: {makeAuthenticateHttpRequest}} = this.props;
        this.setState(deleteTrackHelper.processing());
        await makeAuthenticateHttpRequest({
            method: 'delete',
            path: `/tracks`,
            data: fields
        });
        this.setState(deleteTrackHelper.result(true));
    };

    updateTracksNameGenre = async (data) => {
        console.log(data,"data-+-updateTracksNameGenre--");
        const {auth: {makeAuthenticateHttpRequest}} = this.props;
        const { id, genre, songName } = data;
        try {
            const updateTracksNameGenreRes = await makeAuthenticateHttpRequest({
                method: 'post',
                path: `/tracks/updateTracksNameGenre?trackData=${JSON.stringify({ id, genre, songName })}`
            });
            
            let trackToUpdate = this.state.tracksResult.list.find(track => track.id === id);
            if (trackToUpdate) {
                // Update the necessary fields in trackToUpdate using newData
                trackToUpdate.songName = songName;
              }
              console.log(this.state.tracksResult,"this.state.tracksResult");
            //this.setState({tracksResult: this.normalizeTracks(tracksResult)});
            this.setState({updateTracksNameGenreRes: updateTracksNameGenreRes});
        } catch (error) {
            this.setState(tracksHelper.error(error));
            return [];
        }
    }

    updateTracksEmotions = async (data) => {

        let trackEmotions = [];
        const now = new Date();
        const year = now.getFullYear();
        const month = (now.getMonth() + 1).toString().padStart(2, '0');
        const day = now.getDate().toString().padStart(2, '0');
        const hours = now.getHours().toString().padStart(2, '0');
        const minutes = now.getMinutes().toString().padStart(2, '0');
        const seconds = now.getSeconds().toString().padStart(2, '0');
        const currentTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

        data.map((item,index) => {
            if(
                typeof item['trackVersionId'] !== 'undefined' 
                && item['trackVersionId'] >= 0 
                && typeof item['mood_happy'] !== 'undefined' 
                && item['mood_happy'] >= 0 
                &&  typeof item['mood_sad'] !== 'undefined' 
                && item['mood_sad'] >= 0 
                && typeof item['mood_relaxed'] !== 'undefined' 
                && item['mood_relaxed'] >= 0 
                && typeof item['danceability'] !== 'undefined' 
                && item['danceability'] >= 0 
                && typeof item['mood_aggressive'] !== 'undefined' 
                && item['mood_aggressive'] >= 0
            ){
                trackEmotions.push({
                    trackVersionId:item['trackVersionId'],
                    mood_happy:item['mood_happy'],
                    mood_sad:item['mood_sad'],
                    mood_relaxed:item['mood_relaxed'],
                    mood_danceability:item['danceability'],
                    mood_aggressive:item['mood_aggressive'],
                    created: currentTime
                });
               
            }
            return true;
        });

        try {
            const updateTracksEmotionsRes = await makeHttpRequest({
                method: 'post',
                path: `/tracks/updateTracksEmotions?emotionData=${JSON.stringify(trackEmotions)}`
            });
            this.setState({updateTracksEmotionsRes: updateTracksEmotionsRes});
        } catch (error) {
            this.setState(tracksHelper.error(error));
            return [];
        }
    };	

    getTracksWithoutEmotions = async (sortFields) => {
        try {
            const tracksResultWithoutEmotions = await makeHttpRequest({
                method: 'get',
                path: `/tracks/fetchTracksWithoutEmotions?sortFields=${JSON.stringify(sortFields)}`
            });
            this.setState({tracksResultNew: tracksResultWithoutEmotions});
        } catch (error) {
            this.setState(tracksHelper.error(error));
        }
    };

    getTracksCountWithOrWithoutEmotions = async () => {
        //const {auth: {makeAuthenticateHttpRequest}} = this.props;
        try {
            const tracksCountWithOrWithoutEmotions = await makeHttpRequest({
                method: 'get',
                path: `/tracks/getStatusOfTrackEmotions`
            });
            this.setState({tracksCountWithOrWithoutEmotions: tracksCountWithOrWithoutEmotions});
        } catch (error) {
            this.setState(tracksHelper.error(error));
        }
    };

    fetchPresets = async () => {
        try {
            const allPresets = await makeHttpRequest({
                method: "get",
                path: "/presets"
            });
            this.setState({ allPresets });
        } catch (error) {
            console.log(error);
        }
    };

    getTracks = async ({limit, offset, searchQuery = '', type = 'audio', sortField = 'createdAt', versions = [], genre = ''}) => {
        const {auth: {makeAuthenticateHttpRequest}} = this.props;
        try {
            this.setState({trackFetching: true});
            const tracksResult = await makeAuthenticateHttpRequest({
                method: 'get',
                path: `/tracks/tracksForAdmin?&offset=${offset}&limit=${limit}&type=${type}&searchQuery=${searchQuery.trim()}` +
                    `&sortField=${sortField}&order=DESC&versions=${versions}&genres=${[genre]}`
            });
            this.setState({tracksResult: this.normalizeTracks(tracksResult)});
        } catch (error) {
            this.setState(tracksHelper.error(error));
        } finally {
          this.setState({trackFetching: false});
        }
    };

    addBannerTrack = (track) => {
      try {
        // Clone the existing bannerTracks array from state
        const bannerTracks = [...this.state.bannerTracks];

        console.log(bannerTracks, 'bannerTracks---------addBannerTrack-----');
        // Add the new track to the cloned array
        track && bannerTracks.push(track);
        // Update the state with the new array
        this.setState({ bannerTracks });
      } catch (error) {
          console.log(error);
      }
    };

    removeBannerTrack = (track) => {
      try {
        const { id } = track;
        // Clone the existing bannerTracks array from state
        const bannerTracks = this.state.bannerTracks.filter(track => track.id !== id);

        // Update the state with the new array excluding the removed track
        this.setState({ bannerTracks });
      } catch (error) {
          console.error('Error removing banner track:', error);
      }
    };

    createBanner = async (bannerData) => {
      const { banner, startDate, endDate, title, resetForm, bannerOrder, bannerType, bannerUrl } = bannerData;
      const tracks = this.state.bannerTracks.map(track => track.id).join(',');
      const file = banner;
      const {auth: {makeAuthenticateHttpRequest}} = this.props;
      let isAdded = true;
      // Create FormData and append data
      const formData = new FormData();
      formData.append('title', title);
      formData.append('startDate', startDate);
      formData.append('endDate', endDate);
      formData.append('tracks', tracks);
      formData.append('file', banner);
      formData.append('bannerType', bannerType);
      formData.append('bannerUrl', bannerUrl);
      formData.append('bannerOrder', bannerOrder);
      console.log(file, formData, 'file--------');
      try {
        this.setState({isBannerAdding: true});
        await makeAuthenticateHttpRequest({
          method: 'post',
          data: formData,
          path: '/banners/add',
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
      } catch (e) {
        console.error(e);
        isAdded = false;
        this.setState({
          isBannerAdding: false
        });
      } finally {
        if(isAdded === true){
          this.setState({bannerTracks: {}});
          this.setState({isBannerAdded: true});
          setTimeout(() =>{
            this.setState({isBannerAdded: false});
          }, 3000);
          resetForm();
        }
        this.setState({isBannerAdding: false});
      }
    }

    reUploadlBanner = async (bannerData) => {
      const { id, start, end, bannerOrder } = bannerData;
      const {auth: {makeAuthenticateHttpRequest}} = this.props;
      let isAdded = true;
      console.log(bannerData, 'bannerData====================');
      // Create FormData and append data
      const formData = new FormData();
      formData.append('bannerId', id);
      formData.append('startDate', start);
      formData.append('endDate', end);
      formData.append('bannerOrder', bannerOrder);
      try {
        this.setState({isBannerReAdding: true});
        await makeAuthenticateHttpRequest({
          method: 'post',
          data: formData,
          path: '/banners/re-upload',
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
      } catch (e) {
        console.error(e);
        isAdded = false;
        this.setState({
          isBannerReAdding: false
        });
      } finally {
        if(isAdded === true){
          this.setState({isBannerReAdded: true});
          setTimeout(() =>{
            this.setState({isBannerReAdded: false});
          }, 3000);
        }
        this.setState({isBannerReAdding: false});
      }
    }

    setBannerOrder =  async (bannerData) => {
      const { id, bannerOrder } = bannerData;
      const {auth: {makeAuthenticateHttpRequest}} = this.props;
      let isUpdated = true;
      console.log(bannerData, 'bannerData----------setBannerOrder');
      // Create FormData and append data
      let newBannerOrder = bannerOrder > 1 ? bannerOrder : 1;
      const formData = new FormData();
      formData.append('bannerOrder', newBannerOrder);
      formData.append('id', id);
      try {
        this.setState({isBannerUpdating: true});
        await makeAuthenticateHttpRequest({
          method: 'post',
          data: formData,
          path: '/banners/set-banner-order',
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
      } catch (e) {
        console.error(e);
        isUpdated = false;
        this.setState({
          isBannerUpdating: false,
          isBannerUpdatingRes: {messageColor: 'red', message: 'Please try again later.'},
         });
      } finally {
        await this.fetchBanners({offset: 0});
        if(isUpdated === true){
          this.setState({
            isBannerUpdatingRes: {messageColor: 'green', message: 'Banner order updated successfully.'},
           });
        }
        setTimeout(() =>{
          this.setState({isBannerUpdatingRes: false});
        }, 3000);
        this.setState({isBannerUpdating: false});
      }
    }

    setBannerStatus = async (bannerData) => {
      const { id, status } = bannerData;
      const {auth: {makeAuthenticateHttpRequest}} = this.props;
      let isUpdated = true;
      // Create FormData and append data
      let newStatus = status == 1 ? 0 : 1;
      const formData = new FormData();
      formData.append('status', newStatus);
      formData.append('id', id);
      try {
        this.setState({isBannerUpdating: true});
        await makeAuthenticateHttpRequest({
          method: 'post',
          data: formData,
          path: '/banners/set-status',
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
      } catch (e) {
        console.error(e);
        isUpdated = false;
        this.setState({
          isBannerUpdating: false,
          isBannerUpdatingRes: {messageColor: 'red', message: 'Please try again later.'},
         });
      } finally {
        await this.fetchBanners({offset: 0});
        if(isUpdated === true){
          this.setState({
            isBannerUpdatingRes: {messageColor: 'green', message: 'Banner status updated successfully.'},
          });
        }
        setTimeout(() =>{
          this.setState({isBannerUpdatingRes: false});
        }, 3000);
        this.setState({isBannerUpdating: false});
      }
    }

    fetchBanners = async ({offset}) => {
      // const limit = data.limit ? data.limit : 10;
      offset = offset ? offset : 0;
      try {
        this.setState({ isBannerFetching: true, bannersCount: 0 });
          const {
              banners,
              count
          } = await makeHttpRequest({
            method: "get",
            path: `/banners/all?offset=${offset}`
          });
          this.setState({ banners, bannersCount: count });
      } catch (error) {
        console.log(error);
      } finally {
        this.setState({ isBannerFetching: false });
      }
    };

    normalizeTracks({list, count}) {
        return {
            count,
            list: list.map(elem => {
                elem.isDeleted = elem.isDeleted ? "true" : "false";
                elem.trackVersions.map(version => {
                    version.isDeleted = version.isDeleted ? "true" : "false";
                    version.versionType = `${version.versionType} ${version.subversionType ? '(' + version.subversionType + ')' : ''}`;
                })
                return elem;
            })
        };
    }

    render() {
        return (
            <TracksContext.Provider value={{...this.state, ...this.funcs}}>
                {this.props.children}
            </TracksContext.Provider>
        );
    }
}

export default consumeToContext('auth', AuthContext)(TracksContextContainer);