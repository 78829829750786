import React from 'react';
import Bannerlist from './Bannerlist'
import withRouter from 'components/helpers/withRouter';
import {WidgetHead} from 'components/common/DisplayElems';

function Banners() {
    return <>
        <div className="m-portlet">
            <WidgetHead label="All Banner"/>
            <Bannerlist/>
        </div>
        </>
}

export default withRouter(Banners);
