import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Select} from '../../common/Form';
import {TracksContext} from 'context/TracksContext';

const types = [
  'Remix',
  'Acapella',
  'Acapella Clean',
  'Acapella Dirty',
  'Acapella Intro Acapella Outro',
  'Acapella Intro Outro',
  'Acapella Mega Kutz',
  'Acapella Snip Hitz',
  'Album Version',
  'Clean',
  'Club Edit',
  'Club Mix',
  'Dirty',
  'Dub Mix',
  'Dub',
  'Extra Clean',
  'Extended Clean',
  'Extended Club Mix',
  'Extended Dirty',
  'Extended Dub',
  'Extended Mix',
  'Extended Version',
  'Extended',
  'Freestyle',
  'HQ',
  'Instrumental Mix',
  'Instrumental',
  'Intro',
  'Intro Outro',
  'Acapella Snip Acapella Out',
  'Intro Clean',
  'Intro Extra Clean',
  'Intro Dirty',
  'Intro Outro (Clean)',
  'Intro Outro (Dirty)',
  'Main Mix',
  'Main Version',
  'Main',
  'Mega Kutz',
  'Official Remix',
  'Original Club Mix',
  'Original Edit',
  'Original Extended Mix',
  'Original Mix',
  'Original Radio Edit',
  'Original Radio Mix',
  'Original Version',
  'Original',
  'Radio',
  'Radio Edit (Clean)',
  'Radio Edit',
  'Radio Mix',
  'Radio Version',
  'Remix Acapella',
  'Remix Clean',
  'Remix Dirty',
  'Remix Instrumental',
  'Single',
  'Snip Hitz',
  'Super Clean',
  'Vocal Mix',
  'LP Version',
];

const intro_outro_types = [
  'Acapella Intro Acapella Outro',
  'Acapella Intro Outro',
  'Acapella Mega Kutz',
  'Acapella Snip Hitz',
  'Intro',
  'Intro Clean',
  'Intro Extra Clean',
  'Intro Dirty',
  'Intro Outro',
  'Intro Outro (Clean)',
  'Intro Outro (Dirty)',
  'Mega Kutz',
  'Snip Hitz',
  'Acapella Snip Acapella Out',
];


const Table = ({
  isDataFetching = '',
  headers,
  tableWidth = ['100px', '160px', '500px'],
  data: rawData,
  tableLabel,
  headButton,
  subTable,
  isUploading,
  preset
}) => {
  let data = rawData;
  const {tracksToUpload, getGenresAndCategories, genresResult: {versions, VersionsData} , updateVersion, updateSongName, updateArtistName, removeFromTrackUpload, updateArtisteName } = useContext(TracksContext);

  useEffect(()=>{
    getGenresAndCategories();
  },[])

  const [Versions, setVersions] = useState([{value: '', text: 'All'}]);

  useEffect(() => {
    if (Array.isArray(versions) && versions.length > 0) {
        const sortedVersions = [...versions].sort((a, b) => a.localeCompare(b));
        const mappedVersion = sortedVersions.map((version) => ({
            value: version,
            text: version,
        }));
        setVersions([{ value: '', text: '--NONE--' }, ...mappedVersion]);
    }
}, [versions]);

  if (subTable) {
    data = addSubtableToTable(data);
  }
  const actionWrap = {
    // display: 'flex',
    // gap: '10px',
    // alignItems: 'center',
    // lineHeight: '2.5',
  };
  const handelRemoveFromTrackUpload = async (path) => {
    await removeFromTrackUpload(path);
  }

  const normalizeArtistName = (value) => {
    if (value) {
        let artistName = value.trim();
        artistName = artistName.replace(/^\((.*?)\)/g, '');
        artistName = artistName.replace(/\sft\s.*/, '');
        artistName = artistName.replace(/\sFt\s.*/, '');
        artistName = artistName.replace(/ ft\..*/, '');
        artistName = artistName.replace(/ Ft\..*/, '');
        artistName = artistName.replace(/^\s+/g, '');
        return artistName;
    } else {
        return value;
    }
}
function getVersionType(songName, VersionsData, isIntroOutro, isSpecialCaseInstrumental = false) {
  // console.log(songName, VersionsData, isIntroOutro, isSpecialCaseInstrumental, 'getVersionType------------entered in function');
  const elmVersions = songName.match(/\((.*?)\)/g);
  let matchingVersions = [];
  if(isSpecialCaseInstrumental){
    matchingVersions = VersionsData.reverse().filter(item => {
      return item.name.toLowerCase() === 'instrumental';
    });
    // console.log(matchingVersions, 'matchingVersions---------------3');
    return matchingVersions[0];
  }
  if (!elmVersions || elmVersions.length === 0) {
    matchingVersions = VersionsData.filter(item => {
      return item.name.toLowerCase() === 'main';
    });
    return matchingVersions[0];
  }
  const songNameCleaned = songName.replace(/\)\s*\(/g, ')(');
  let isClean = elmVersions && elmVersions.some(version => version.toLowerCase() === "(clean)" || version.toLowerCase().includes('clean'));
  let isDirty = elmVersions && elmVersions.some(version => version.toLowerCase() === "(dirty)" || version.toLowerCase().includes('dirty'));
  let isSnipHitz = elmVersions && elmVersions.some(version => version.toLowerCase() === "(snip hitz)" || version.toLowerCase().includes('snip hitz'));
  
  matchingVersions = ((!isDirty || !isClean) && (!isSnipHitz)) && VersionsData.sort((a,b) => b.displayName.length - a.displayName.length).filter(item => {
    const { displayName } = item;
    // console.log({songNameCleaned, displayName, isHas: songNameCleaned.toLowerCase().includes(displayName.toLowerCase().replace(/\)\s*\(/g, ')('))}, 'isIntroOutro---------------1');
    return songNameCleaned.toLowerCase().includes(displayName.toLowerCase().replace(/\)\s*\(/g, ')('));
  });
  if (matchingVersions && matchingVersions.length === 1) {
    // console.log(matchingVersions, 'matchingVersions---------------4');
    return matchingVersions[0];
  } else if (matchingVersions && matchingVersions.length > 1){
    matchingVersions = [...matchingVersions].sort((a, b) => b.name.length - a.name.length);
    matchingVersions = matchingVersions.find(item => {
      const { displayName } = item;
      return songNameCleaned.toLowerCase().includes(displayName.toLowerCase().replace(/\)\s*\(/g, ')(').trim());
    });
    if(matchingVersions){
      // console.log(matchingVersions, 'matchingVersions---------------5');
      return matchingVersions;
    }
    return '';
  } else{
    // console.log(isIntroOutro, 'isIntroOutro---------------2');
    matchingVersions = VersionsData.reverse().filter(item => {
      const { name } = item;
      return songNameCleaned.toLowerCase().includes(`(${name.toLowerCase()})`);
    });
    // console.log(isIntroOutro, matchingVersions, 'isIntroOutro---------------3');
    if(matchingVersions && matchingVersions.length === 1 && (((!isDirty || !isClean) && (!isSnipHitz)) || ((isDirty || isClean) && (isSnipHitz)))){
      return matchingVersions[0];
    }
    if(matchingVersions && matchingVersions.length > 1){
      matchingVersions = [...VersionsData].sort((a, b) => b.name.length - a.name.length);
      matchingVersions = matchingVersions.find(item => {
        const { displayName } = item;
        return songNameCleaned.toLowerCase().includes(displayName.toLowerCase().trim());
      });
      // console.log(isIntroOutro, matchingVersions, 'isIntroOutro---------------3--A');
      if(matchingVersions){
        // console.log(isIntroOutro, matchingVersions, 'isIntroOutro---------------11-3');
        return matchingVersions;
      }
    }
    // if(isIntroOutro){
    // without special case snip hitz
    matchingVersions = ((isSnipHitz)) && VersionsData.filter(item => {
      const { displayName } = item;
      // console.log(isIntroOutro, 'isIntroOutro---------------11');
      if(displayName.toLowerCase().trim() !== '(clean)' && displayName.toLowerCase().trim() !== '(dirty)' ){
        // console.log(displayName, displayName.toLowerCase().trim() !== '(clean)' , displayName.toLowerCase().trim() !== '(dirty)', "displayName.toLowerCase().trim() !== '(clean)' || displayName.toLowerCase().trim() !== '(dirty)' " )
        return songNameCleaned.toLowerCase().includes(displayName.toLowerCase());
      }
    });
    if (matchingVersions && matchingVersions.length === 1) {
      // console.log(matchingVersions, 'isIntroOutro---------------11-1');
      return matchingVersions[0];
    } else if (matchingVersions && matchingVersions.length > 1){
      matchingVersions = [...matchingVersions].sort((a, b) => b.name.length - a.name.length);
      matchingVersions = matchingVersions.find(item => {
        const { displayName } = item;
        return songNameCleaned.toLowerCase().includes(displayName.toLowerCase().trim());
      });
      // console.log(isIntroOutro, matchingVersions, 'isIntroOutro---------------11-2');
      if(matchingVersions){
        // console.log(isIntroOutro, matchingVersions, 'isIntroOutro---------------11-3');
        return matchingVersions;
      }
      return '';
    }
    if ((isDirty || isClean) && (isSnipHitz)){
      matchingVersions = VersionsData.reverse().filter(item => {
        return item.name.toLowerCase() === 'main';
      });
      // console.log(isIntroOutro, matchingVersions, 'isIntroOutro---------------5');
      return matchingVersions[0];
    }

    matchingVersions = VersionsData.sort((a, b) => b.displayName.length - a.displayName.length).find(v => {
        const lowercaseDisplayName = v.displayName.toLowerCase();
        return elmVersions.some(version => lowercaseDisplayName.includes(version.toLowerCase()));
    });
    console.log(matchingVersions, 'matchingVersions----------matchingVersions-----matchingVersions');
    if (matchingVersions) {
      return matchingVersions;
    }
  // }
    matchingVersions = VersionsData.reverse().filter(item => {
      return item.name.toLowerCase() === 'main';
    });
    // console.log(isIntroOutro, matchingVersions, 'isIntroOutro---------------6');
    return matchingVersions[0];
  }
}
function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}
  const isRemix = Boolean(preset && preset.toLowerCase() === "remixes");
  const isIntroOutro = Boolean(preset && preset.toLowerCase() === 'intro / outro');
  return [
    <WidgetHead key="widgetHead" label={tableLabel} button={headButton} />,
    <div key="widgetBody" className="m-portlet__body">
      <div className="m-section">
        <div className="m-section__content">
          <table className="table m-table m-table--head-bg-success">
            <thead key="head">
              <tr>
                {headers.map((header, index) => {
                  return (
                    <th
                      className={index === headers.length - 1 ? 'text-center' : ''}
                      key={header}
                    >
                      {header}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody key="body">
              {data.length === 0 && isDataFetching !== '' && isDataFetching === true && (
                <tr>
                  <th className="text-center" colSpan={8}>
                    Loading....
                  </th>
                </tr>
              )}
              {data.length > 0 &&
                data.map((elem, index) => {
                    if (elem.title) {
                        const titleParts = elem.title.split('-');
                        if (titleParts.length >= 2) {
                          const songNameGenre = (titleParts.length === 3) ? titleParts[2].trim() : titleParts[1].trim();
                          const serialNo = (titleParts.length === 3) ? titleParts[0].trim() : '';
                          const artisteName = (titleParts.length === 3) ? titleParts[1].trim() : titleParts[0].trim();
                          let song_name = songNameGenre && songNameGenre.replace(/_/g, ' ').replace('.mp3', '').trim();
                          let songName = song_name ? song_name.replace(/"/g, "'").replace('.mp3', '').replace('.mp4', '').replace('.avi', '').trim() : '';
                          let elmVersions = songName && songName.match(/\((.*?)\)/g);
                          let withProblem = true;
                          let subversionType = '';
                          let isSpecialCaseInstrumental = false;
                          let artistName = normalizeArtistName(artisteName);
                          if(!artistName || artistName.length < 0){
                            let artisteNameArr = artisteName && artisteName.match(/\((.*?)\)/g);
                            if(artisteNameArr && artisteNameArr.length > 0){
                              isSpecialCaseInstrumental = true;
                              artistName = artisteNameArr[0].replace(/\(|\)/g, '').trim();
                            } else {
                              return (
                                <tr key={elem.id || index}>
                                  <th colSpan={4}>Unable to find the artist name in file name: {elem.title}</th>
                                </tr>
                              );
                            }
                          }
                          let versionTitle = titleParts.length-1;
                          let version = getVersionType(titleParts[versionTitle], VersionsData, isIntroOutro, isSpecialCaseInstrumental);
                          // console.log(version, 'version------recieved from getVersionType');
                          let versionType = (version && version.name) ? version.name : '';
                          // let additionalVersions = '';
                          if (elmVersions) {
                            // elmVersions = elmVersions.map(version => version.replace(/(\(|\))/g, ''));
                            // const suitableIntroOutroVersion = elmVersions.reverse().filter(x => intro_outro_types.includes(x))[0];
                            // if (isIntroOutro && suitableIntroOutroVersion) {
                            //     subversionType = suitableIntroOutroVersion;
                            //     elmVersions.splice(elmVersions.indexOf(suitableIntroOutroVersion), 1);
                            // }
                            if(versionType !== ''){
                              songName = songName.replace(/\)\s*\(/g, ')(')
                              let versionRem = version.displayName.replace(/\)\s*\(/g, ')(');
                              songName =  songName.replace(versionRem, '').replace(/\s+/g, ' ').replace(/\)\s*\(/g, ') (').trim();
                            }else{
                              songName = songName.trim();
                            }
                          //  if(!isRemix){
                              //songName = songName.replace(/\(.*/, '').trim();
                          //  }else{
                              const currentTrack = tracksToUpload.filter(el => el.metadata.title.toLowerCase() === elem.title.toLowerCase());
                              if(currentTrack[0].metadata.version){
                              VersionsData.sort((a, b) => b.displayName.length - a.displayName.length).forEach((item) => {
                                const { displayName, name } = item;
                                let versionRem = displayName.replace(/\)\s*\(/g, ')(');
                                songName = songName.replace(/\)\s*\(/g, ')(');
                                //console.log(displayName,'displayName--------');
                                
                                if (new RegExp(escapeRegExp(versionRem), 'ig').test(songName)) {
                                  songName = songName.replace(new RegExp(escapeRegExp(versionRem), 'ig'), '');
                                  console.log(versionRem, version, songName, 'versionRem-------2');
                                } else if (new RegExp(`(${name})`, 'ig').test(songName)) {
                                  let versionRem = name.replace(/\)\s*\(/g, ')(');
                                  console.log(versionRem, version, songName, 'versionRem-------1a');
                                  songName = songName.replace(new RegExp(escapeRegExp(`(${versionRem})`), 'ig'), '');
                                  console.log(versionRem, version, songName, 'versionRem-------1b');
                                }
                                isIntroOutro && elmVersions.forEach(elm => {
                                  let versionNames = displayName && displayName.match(/\((.*?)\)/g);
                                  let nameToCompare = versionNames[0].replace(/[()]/g, "");
                                  // console.log(elm,elm.toLowerCase(),nameToCompare.toLowerCase(), 'elm-----------00')
                                  if (elm.toLowerCase().replace(/[()]/g, "") === nameToCompare.toLowerCase()) {
                                    songName = songName.replace(new RegExp(escapeRegExp(elm), 'g'), "");
                                    // console.log(elm, 'elm-----------matched')
                                  }
                                });

                                songName = songName.replace(/\)\(/g, ') (').replace(/\s+/g, " ").trim();
                                // if(songName.includes(displayName)){
                                //   let versionRem = displayName.replace(/\)\s*\(/g, ')(');
                                //   songName = songName.replace(/\)\s*\(/g, ')(').replace(new RegExp(`\\(${versionRem}\\)`, 'ig'),'').replace(/\)\(/g, ') (');
                                //   console.log(versionRem,version,'versionRem-------3')
                                // }
                                // if(songName.includes(name)){
                                //   songName = songName.replace(new RegExp(`\\(${name}\\)`, 'ig'),'').trim();
                                // }
                              });
                            }
                           // }
                          }
                            const serialNoDisplay = serialNo ? serialNo : '';
                            const track = tracksToUpload.filter(el => el.metadata.title.toLowerCase() === elem.title.toLowerCase());
                           
                          console.log(versionType, track[0].metadata.version,'versionType, track[0].metadata.version');
                            if((track[0].metadata.trackVersionType !== versionType && (typeof track[0].metadata.trackVersionTypeUpdated === 'undefined' || track[0].metadata.trackVersionTypeUpdated !== true )) || track[0].metadata.trackVersionType == null || typeof track[0].metadata.trackVersionType == 'undefined'){
                              updateVersion(elem.title, versionType);
                            }else{
                              versionType = track[0].metadata.trackVersionType;
                            }

                            if(track[0].metadata.songName !== songName){
                              updateSongName(elem.title, songName);
                            }

                            if(track[0].metadata.artist !== artistName){
                              updateArtistName(elem.title, artistName);
                            }

                            if((track[0].metadata.artisteName !== artisteName || !track[0].metadata.artisteName) && artisteName){
                              updateArtisteName(elem.title, artisteName);
                            }
                            console.log(elem, elem.metadata, 'elem, elem.metadata, elem.metadata.status');
                          return (
                            <tr
                              key={elem.id || index}
                              style={{ background: elem.isSubtable && subTable.background }}
                            >
                                <td>{serialNoDisplay}</td>                                                   
                                <td>{artistName}</td>
                                <td>{songName}</td>
                                <td>
                                    <Select name="Versions"
                                        label="Versions"
                                        className="w-100 mr-5 justify-content-start"
                                        value={versionType}
                                        options={Versions}
                                        onChange={(event) => updateVersion(elem.title, event.target.value)}
                                        labelClass={'pr-3'}
                                    />
                                </td>
                                <td style={{ ...actionWrap, color: elem.color, fontWeight: 700 }}>{(elem && elem.status) ? elem.status : 'In queue'}</td>
                                <td style={actionWrap}>
                                {!isUploading && (
                                  <span onClick={() => handelRemoveFromTrackUpload(elem.file.path)} className='btn flaticon-delete'></span>
                                )}
                              </td>
                            </tr>
                          );
                        } else {
                          return (
                            <tr key={elem.id || index}>
                              <th colSpan={4}>Track title does not match the expected pattern: {elem.title}</th>
                            </tr>
                          );
                        }
                      }
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>,
  ];
};

Table.propTypes = {
  isDataFetching: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  headers: PropTypes.array.isRequired,
  tableWidth: PropTypes.array,
  data: PropTypes.array.isRequired,
  tableLabel: PropTypes.string,
  headButton: PropTypes.object,
  buttons: PropTypes.array,
  select: PropTypes.bool,
  subTable: PropTypes.object,
};

function WidgetHead({ label, button }) {
  return (
    <div className="m-portlet__head">
      <div className="m-portlet__head-caption">
        <div className="m-portlet__head-title">
          <span className="m-portlet__head-icon">
            <i className="flaticon-file-1" />
          </span>
          <h3 className="m-portlet__head-text">{label}</h3>
        </div>
      </div>
      {button && (
        <div className="m-portlet__head-tools">
          <button
            className="btn btn-primary m-btn m-btn--icon m-btn--air m-btn--pill"
            onClick={button.onClick}
          >
            <span>
              <i className={`la ${button.icon}`} />
              <span>{button.label}</span>
            </span>
          </button>
        </div>
      )}
    </div>
  );
}

WidgetHead.propTypes = {
  label: PropTypes.string.isRequired,
  button: PropTypes.object,
};

function addSubtableToTable(data) {
  return data.reduce((result, elem) => {
    result.push(elem);
    if (elem.subTable) {
      elem.subTable.forEach((subTableElem) => {
        subTableElem.isSubtable = true;
        result.push(subTableElem);
      });
    }
    return result;
  }, []);
}

export default Table;
