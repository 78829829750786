import React from 'react';
export default function Paginator({options, limitOnChange, offsetOnChange, count, limit, offset}) {
    const paginatorButtons = [];
    const startPaginationValue = Math.floor(offset/limit) > 2 ? Math.floor(offset/limit)-1 : 1;
    for (let i = startPaginationValue ; (i - 1) < count / limit; i++) {
        paginatorButtons.push(i);
        if (paginatorButtons.length > 4) {break;}
    }
    function setOffset(type) {
        let newOffset = 0;
        switch(type) {
            case 'first':
                newOffset = 0;
                break;
            case 'last':
                newOffset = count - count%limit;
                break;
            case 'prev':
                newOffset = (offset > limit) ? (offset - limit) : 0;
                break;
            case 'next':
                newOffset = (offset + limit < count) ? (offset + limit) : (count - count%limit);
                break;
            default:
                newOffset = offset;
        }
        offsetOnChange(newOffset);
    }

    return (
        <div className="col-sm-12 col-md-7 dataTables_pager d-flex flex-row align-content-center justify-content-end">
            {options && options.length > 0 && 
                <div className="dataTables_length  d-flex flex-row ">
                    <label className="d-flex flex-row align-items-center  ml-5 mr-5">
                        Show
                        <select
                            onChange={(event) => limitOnChange(event)}
                            name="m_table_1_length"
                            aria-controls="m_table_1"
                            style={{width:'46px'}}
                            className="custom-select custom-select-sm form-control form-control-sm ml-2 mr-2 ">
                            {options.map(option => <option key={option.text} value={option.value}>{option.text}</option>)}
                        </select>
                        entries
                    </label>
                </div>
            }
            <div className="dataTables_paginate paging_simple_numbers">
                <ul className="pagination m-0">
                    <li className="paginate_button page-item previous">
                        <button
                            onClick={() => setOffset('first')}
                            aria-controls="m_table_1"
                            className="page-link">
                            <i className="la la-angle-double-left"/>
                        </button>
                    </li>
                    {/* <li className="paginate_button page-item previous">
                        <button
                            onClick={() => setOffset('prev')}
                            aria-controls="m_table_1"
                            className="page-link">
                            <i className="la la-angle-left"/>
                        </button>
                    </li> */}
                    {paginatorButtons.map(offsetValue => {
                        return (
                            <li key={offsetValue}
                                className={"paginate_button page-item " + (limit * (offsetValue - 1) === offset ? 'active' : '')}>
                            <button
                                key={offsetValue}
                                aria-controls="m_table_1"
                                onClick={() => offsetOnChange(limit * (offsetValue - 1))}
                                className="page-link">{offsetValue}</button>
                        </li>);
                    })}

                    {/* <li className="paginate_button page-item next">
                        <button
                            onClick={() => setOffset('next')}
                            aria-controls="m_table_1"
                            className="page-link">
                            <i className="la la-angle-right"/>
                        </button>
                    </li> */}
                    <li className="paginate_button page-item next">
                        <button
                            onClick={() => setOffset('last')}
                            aria-controls="m_table_1"
                            className="page-link">
                            <i className="la la-angle-double-right"/>
                        </button>
                    </li>
            </ul>
        </div>
    </div>)
}
